import { Logout } from '@oresundsbron/icons';
import { PrivateCustomer } from '@oresundsbron/validators';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useLogout } from '../../../hooks/useLogout';
import { NextPageWithLayout } from '../../../pages/_app';
import CustomerStatus from './CustomerStatus';
import PrivateDashboard from './Private';
import { Box, Button } from '@oresundsbron/bridge-ui';
import { getCustomerStatus } from './utils';
import { useLocale } from '~/stores/locale';
import { useCustomerInformation } from '@oresundsbron/app-common';

const Dashboard: NextPageWithLayout = () => {
  const { t } = useTranslation(['account', 'common'], {
    nsMode: 'fallback',
  });

  const { customerInformation } = useCustomerInformation();
  const customerStatus = useMemo(
    () => getCustomerStatus(customerInformation as PrivateCustomer | undefined),
    [customerInformation]
  );
  const onLogout = useLogout();

  return (
    <Box className="my-4 md:my-6">
      <CustomerStatus status={customerStatus} />
      <PrivateDashboard />
      <Box className="mt-5 flex justify-center">
        <Button intent="solid" startIcon={<Logout />} onClick={onLogout}>
          {t('action.logout')}
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
