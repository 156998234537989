import { PrivateCustomer, constants } from '@oresundsbron/validators';

export const isDashboardEntry = <T extends { key: string }>(entry: T) =>
  entry.key === 'dashboard';

export interface CustomerStatus {
  backgroundColor: string;
  translationKey: string;
}

export const getCustomerStatus = (
  customer?: PrivateCustomer
): CustomerStatus | undefined => {
  if (!customer) {
    return undefined;
  }

  switch (customer.status) {
    case constants.customerStatus.inactive:
      return {
        backgroundColor: 'bg-red-300',
        translationKey: 'dashboard.inactive',
      };
    case constants.customerStatus.pending:
      return {
        backgroundColor: 'bg-yellow-200',
        translationKey: 'dashboard.pending',
      };
    case constants.customerStatus.pendingInvoice:
      return {
        backgroundColor: 'bg-yellow-200',
        translationKey: 'dashboard.pending_invoice',
      };
    case constants.customerStatus.cardExpired:
    case constants.customerStatus.blocked:
      return {
        backgroundColor: 'bg-red-300',
        translationKey: 'dashboard.blocked',
      };
    default:
      return undefined;
  }
};
